.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.App:has(.admin-theme) {
	width: 100%;
	max-width: 100%;
	box-shadow: none;
	font-size: 15px;
	background: #fff;
}
html {
	background: #f5f5f5;	
}
html:has(.admin-theme) {
  font-size: 16px!important;
  background: #fff;
}
.main .header {
    margin-left: -.32rem;
    margin-right: -.32rem;
    position: relative;
	background: #fbcf38;
}
.main .header-right .header-profile span {
	color: #1fc1ff;
	font-weight: bold;
}
.main .header-right .header-profile {
	color: #ff3447;
	font-weight: bold;
}